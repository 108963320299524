import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Admin from "./Admin.js";
import { globalContext, GlobalContextProvider } from "./Context.js";
import { showInfoDialog, fetchWithRetry, verifyUser, sleep } from "./utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEnvelope,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalConfirm } from "./Modal.js";

async function signIn(
  state,
  dispatch,
  setSignInPhase,
  setErrorMsg,
  email,
  password
) {
  setSignInPhase(1);

  const endpoint = "https://api.svatbenocafe.com/login.php";
  const params = {
    email: email,
    password: password,
  };
  await fetchWithRetry(
    endpoint,
    "post",
    params,
    null,
    async (json) => {
      dispatch({
        type: "user",
        payload: {
          token: json.token,
          id: json.user.id,
          email: json.user.email,
          superAdmin: json.user.superAdmin,
        },
      });
      state.login();
    },
    async (response) => {
      setSignInPhase(2);
      if (response) {
        try {
          const json = await response.json();
          if (json.message) {
            setErrorMsg(json.message);
            return;
          }
        } catch (e) {}
      }
      setErrorMsg("Wrong username or password!");
    },
    {},
    1
  );
}

async function forgottenPassword(email) {
  await fetchWithRetry(
    "https://api.svatbenocafe.com/forgotten-password.php",
    "post",
    {
      email: email,
    }
  );
}
const Login = (props) => {
  const [signInPhase, setSignInPhase] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("Wrong Username or Password");

  const { state, dispatch } = useContext(globalContext);
  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div
        className={`card card-signin card-signin mt-5 ${
          signInPhase === 1 ? "awaiting" : ""
        }`}
      >
        <div className="card-body text-dark">
          <h3 className="card-title text-center">
            <FontAwesomeIcon icon={faSignInAlt} /> Sign In
          </h3>

          <form
            className="text-center"
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();
              signIn(
                state,
                dispatch,
                setSignInPhase,
                setErrorMsg,
                username,
                password
              );
            }}
          >
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-red btn-block text-uppercase"
            >
              Log In
            </button>
            <a
              href="#/"
              className="text-red text-muted d-inline-block mt-2"
              onClick={(e) => {
                e.preventDefault();
                if (document.getElementById("inputEmail").checkValidity()) {
                  let header = (
                    <span className="text-red">
                      <FontAwesomeIcon icon={faEnvelope} /> Email sent
                    </span>
                  );
                  showInfoDialog(
                    dispatch,
                    header,
                    `A password reset link was sent to ${username}.`
                  );
                  forgottenPassword(username);
                } else {
                  document.getElementById("inputEmail").focus();
                }
              }}
            >
              Forgotten Password
            </a>
            {signInPhase === 2 && (
              <h5 className="text-danger mt-2">{errorMsg}</h5>
            )}
          </form>
        </div>
      </div>
      {signInPhase === 1 && (
        <div className="fixed-center">
          <div
            className="spinner-border text-red text-center mx-auto my-5"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

const MainPage = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [userIsSignedIn, setUserIsSignedIn] = useState(
    state.user.token ? 2 : 0
  );

  useEffect(() => {
    dispatch({
      type: "login",
      payload: () => {
        setUserIsSignedIn(1);
      },
    });

    if (state.user.token) {
      verifyUser(
        state.user.token,
        async (user, token) => {
          dispatch({
            type: "user",
            payload: {
              token: token,
              id: user.id,
              email: user.email,
              superAdmin: user.superAdmin,
            },
          });
          setUserIsSignedIn(1);
        },
        () => {
          dispatch({ type: "removeToken", payload: null });
          setUserIsSignedIn(0);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          {userIsSignedIn === 1 ? (
            <Route key="/adminLogged" path="/">
              <Admin />
            </Route>
          ) : (
            <Route key="/admin" path="/" component={Login} />
          )}

          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
      <Modal
        id="infoDialog"
        header={state.infoDialog.header}
        content={state.infoDialog.content}
        large={state.infoDialog.large}
        closeFn={state.infoDialog.closeFn}
      />
      <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
      />
      <footer
        className="position-relative footer text-red-light text-center mt-auto py-2"
      >
        <div className="container ">
          <div>
            <FontAwesomeIcon icon={faEnvelope} /> office
            <FontAwesomeIcon icon={faAt} />
            eventcafeboutique.com
          </div>
          <div>
            Event Cafe Boutique &copy; 2010-{new Date().getFullYear()} All
            rights reserved.
          </div>

          <div className="developed-by small font-italic">
            Developed by{" "}
            <a
              href="https://www.insentico.com"
              rel="noopener noreferrer"
              target="_blank"
              className="text-red"
            >
              Insentico Ltd.
            </a>
          </div>
          <div className="ads small font-italic d-none">
            <a
              href="https://www.easycryptobot.com"
              rel="noopener noreferrer"
              target="_blank"
              className="text-red"
            >
              easycryptobot
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

const App = (props) => {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
};

export default App;
