import React, { createContext, useReducer } from "react";


function getUserToken() {
  let token = localStorage.getItem("token");
  return token;
}

let initialState = {
  infoDialog: { header: "", content: "" },
  confirmDialog: { header: "", content: "", func: null },
  login: () => {},
  logout: async function (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("sa");
    window.location.reload();
  },
  user: {
    token: getUserToken(),
    id: "",
    email: "",
    superAdmin: "",
  },
};
let globalContext = createContext(initialState);

let globalContextReducer = (state, action) => {
  switch (action.type) {
    case "infoDialog":
      return { ...state, infoDialog: action.payload };
    case "confirmDialog":
      return { ...state, confirmDialog: action.payload };
    case "login":
      return { ...state, login: action.payload };
    case "user":
      localStorage.setItem("token", action.payload.token);
      return { ...state, user: action.payload };
      case "removeToken":
        localStorage.removeItem("token");
        return state;
    default:
      return state;
  }
};

const GlobalContextProvider = (props) => {
  let [state, dispatch] = useReducer(globalContextReducer, initialState);
  let value = { state, dispatch };

  return (
    <globalContext.Provider value={value}>
      {props.children}
    </globalContext.Provider>
  );
};

export { GlobalContextProvider, globalContext };
