import $ from "jquery";

function getLocalContent(language, ...args) {
  switch (language) {
    case "en":
      return args[0];
    case "bg":
      return args[1];
    default:
      return args[0];
  }
}
function isVisibleById(id) {
  let element = window.document.getElementById(id);

  return element ? window.getComputedStyle(element).display !== "none" : false;
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function showInfoDialog(dispatch, header, content, large, closeFn) {
  dispatch({
    type: "infoDialog",
    payload: {
      header: header,
      content: content,
      large: large,
      closeFn: closeFn,
    },
  });
  if (!$("#infoDialog").is(":visible")) {
    $("#infoDialog").modal("toggle");
  }
}
function showConfirmDialog(dispatch, header, content, func, large) {
  dispatch({
    type: "confirmDialog",
    payload: { header: header, content: content, func: func, large: large },
  });
  if (!$("#confirmDialog").is(":visible")) {
    $("#confirmDialog").modal("toggle");
  }
}

async function fetchWithRetry(
  endpoint,
  methodType,
  bodyParams,
  formData,
  okFunc = null,
  errorFunc = null,
  headers = {},
  maxRetries = 3,
  retry = 1
) {
  let doRetry = false;
  let response = null;
  try {
    let params = {
      method: methodType,
      headers: {
        ...headers,
        Accept: "application/json",
        //"Content-Type": "application/json",
      },
    };
    if (bodyParams) {
      params.body = JSON.stringify(bodyParams);
    }
    if (formData) {
      params.body = formData;
    }

    response = await fetch(endpoint, params);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      if (okFunc) {
        try {
          okFunc(json);
        } catch (e) {}
      }
      return;
    } else {
      doRetry = true;
    }
  } catch (e) {
    doRetry = true;
  }
  if (doRetry) {
    if (retry < maxRetries) {
      await sleep(1000);
      fetchWithRetry(
        endpoint,
        methodType,
        bodyParams,
        formData,
        okFunc,
        errorFunc,
        headers,
        maxRetries,
        retry + 1
      );
      return;
    }
  }

  if (errorFunc) {
    try {
      const json = await response.json();
      errorFunc(json);
    } catch (e) {
      errorFunc(response);
    }
  }
}

const albumsByCategory = {};

async function getAlbumsByCategory(category, setFetchStatus) {
  if (!albumsByCategory[category]) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/galleries.php?category=${category}`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json && json.length > 0) {
              albumsByCategory[category] = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(albumsByCategory[category]);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve([]);
            }
          } catch (e) {
            //console.log("error in getWedingsByCategory: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve([]);
          }
        },
        () => {
          //console.log("no weddings");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve([]);
        }
      );
    });
  } else {
    return albumsByCategory[category];
  }
}

const albums = {};

async function getAlbum(id, setFetchStatus) {
  if (!albums[id]) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/galleries.php?id=${id}`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json) {
              albums[id] = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(albums[id]);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve(null);
            }
          } catch (e) {
            //console.log("error in getAlbum: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve(null);
          }
        },
        () => {
          //console.log("missing album");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve(null);
        }
      );
    });
  } else {
    return albums[id];
  }
}
async function getAlbumsForReorder(
  category,
  setAlbums,
  setErrorMsg,
  setGetAlbumsState,
  componentIsMounted,
  url
) {
  let elements = await getAlbumsByCategory(category, setGetAlbumsState);
  elements = elements.map((el) => {
    let newEl = { ...el };
    newEl.primaryImage = `${url}${el.primaryImage}`;
    return newEl;
  });
  if (elements.length > 0) {
    if (componentIsMounted.current) {
      setErrorMsg(null);
      setAlbums(elements);
    }
  }
  if (elements.length === 0 && componentIsMounted.current) {
    if (componentIsMounted.current) {
      setAlbums([]);
      setErrorMsg("Connection to database failed or category has no albums.");
    }
  }
}

let reviews = [];

async function getReviews(setFetchStatus) {
  if (reviews.length === 0) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/reviews.php`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json && json.length > 0) {
              reviews = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(reviews);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve([]);
            }
          } catch (e) {
            //console.log("error in getReviews: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve([]);
          }
        },
        () => {
          //console.log("no data");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve([]);
        }
      );
    });
  } else {
    return reviews;
  }
}

let siteText = {};

async function getSiteText(id, setFetchStatus) {
  if (!siteText[id]) {
    if (setFetchStatus) {
      setFetchStatus(2);
    }
    return new Promise(async (resolve) => {
      let endpoint = `https://api.svatbenocafe.com/text.php?id=${id}`;
      await fetchWithRetry(
        endpoint,
        "get",
        null,
        null,
        async (json) => {
          try {
            if (json) {
              siteText[id] = json;
              if (setFetchStatus) {
                setFetchStatus(0);
              }
              resolve(siteText);
            } else {
              //console.log("no data");
              if (setFetchStatus) {
                setFetchStatus(1);
              }
              resolve(null);
            }
          } catch (e) {
            //console.log("error in getSiteText: " + e);
            if (setFetchStatus) {
              setFetchStatus(1);
            }
            resolve(null);
          }
        },
        () => {
          //console.log("no data");
          if (setFetchStatus) {
            setFetchStatus(1);
          }
          resolve(null);
        }
      );
    });
  } else {
    if (setFetchStatus) {
      setFetchStatus(0);
    }
    return siteText;
  }
}

function dataURLtoBlob(dataurl, type) {
  let arr = dataurl.split(",");
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: type });
}

const readVideoURL = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};
const readImageURL = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let img = new Image();
      img.src = e.target.result;
      img.onload = (e) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        let MAX_WIDTH = 800;
        let MAX_HEIGHT = 800;
        let width = img.width;
        let height = img.height;
        let needResize = false;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
            needResize = true;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
            needResize = true;
          }
        }

        if (!needResize) {
          res({ blob: file, url: img.src, w: width, h: height });
          return;
        }

        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        let dataurl = canvas.toDataURL(file.type);
        let blob = dataURLtoBlob(dataurl, file.type);
        //res(dataurl);
        res({ blob: blob, url: dataurl, w: width, h: height });
      };

      img.onerror = (e) => rej(e);

      //res(e.target.result);
    };

    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

const getWidthHeight = (src) => {
  return new Promise((res, rej) => {
    let img = new Image();
    img.src = src;
    // img.onload = function () {
    //   res({w: this.width, h: this.height})
    // }
    img.onload = (e) => res({ w: e.target.width, h: e.target.height });

    img.onerror = (e) => rej(e);
  });
};

async function verifyUser(userToken, okFunc, errorFunc) {
  if (!userToken) {
    errorFunc();
    return;
  }

  await fetchWithRetry(
    `https://api.svatbenocafe.com/login.php`,
    "get",
    null,
    null,
    (json) => {
      if (json && json.user && json.token) {
        okFunc(json.user, json.token);
      } else {
        errorFunc();
      }
    },
    errorFunc,
    { Authorization: `Bearer ${userToken}` }
  );
}
async function changePassword(
  token,
  email,
  password,
  newPassword,
  newPassword2,
  setErrorMsg,
  setChangePasswordState,
  setPassword,
  setNewPassword,
  setNewPassword2,
  componentIsMounted,
  setDisabled
) {
  if (newPassword !== newPassword2) {
    setErrorMsg("The retyped password does not match the new password!");
    return;
  }
  setChangePasswordState(2);
  setDisabled(true);
  await fetchWithRetry(
    `https://api.svatbenocafe.com/password.php`,
    "post",
    {
      email: email,
      password: password,
      newPassword: newPassword,
    },
    null,
    (json) => {
      if (componentIsMounted.current) {
        setErrorMsg(null);
        setChangePasswordState(1);
        setPassword("");
        setNewPassword("");
        setNewPassword2("");
        setDisabled(false);
      }
    },
    () => {
      if (componentIsMounted.current) {
        setErrorMsg("Wrong password!");
        setChangePasswordState(0);
        setDisabled(false);
      }
    },
    { Authorization: `Bearer ${token}` }
  );
}

async function createAdmin(
  token,
  newAdminEmail,
  newAdminPassword,
  setErrorMsg,
  setCreateAdminState,
  setNewAdminEmail,
  setNewAdminPassword,
  componentIsMounted,
  setDisabled
) {
  setCreateAdminState(2);
  setDisabled(true);
  await fetchWithRetry(
    `https://api.svatbenocafe.com/admin.php`,
    "post",
    {
      email: newAdminEmail,
      password: newAdminPassword,
    },
    null,
    (json) => {
      if (componentIsMounted.current) {
        setErrorMsg(null);
        setCreateAdminState(1);
        setNewAdminEmail("");
        setNewAdminPassword("");
        setDisabled(false);
      }
    },
    () => {
      if (componentIsMounted.current) {
        setErrorMsg("Admin creation failed");
        setCreateAdminState(0);
        setDisabled(false);
      }
    },
    { Authorization: `Bearer ${token}` }
  );
}
async function deleteAdmin(
  token,
  adminForDelete,
  setAdminForDelete,
  allAdmins,
  setAllAdmins,
  setErrorMsg,
  setDeleteAdminState,
  componentIsMounted,
  setDisabled
) {
  setDeleteAdminState(2);
  setDisabled(true);
  await fetchWithRetry(
    `https://api.svatbenocafe.com/admin.php`,
    "delete",
    {
      id: adminForDelete.id,
    },
    null,
    (json) => {
      if (componentIsMounted.current) {
        setErrorMsg(null);
        setDeleteAdminState(1);
        setAdminForDelete({
          id: "placeholder",
          email: "Select admin profile",
        });
        setAllAdmins(allAdmins.filter((el) => el.id !== adminForDelete.id));
        setDisabled(false);
      }
    },
    () => {
      if (componentIsMounted.current) {
        setErrorMsg("Admin deletion failed");
        setDeleteAdminState(0);
        setDisabled(false);
      }
    },
    { Authorization: `Bearer ${token}` }
  );
}
async function getAdmins(
  token,
  setErrorMsg,
  setAllAdmins,
  componentIsMounted,
  setDisabled
) {
  setDisabled(true);
  await fetchWithRetry(
    `https://api.svatbenocafe.com/admin.php`,
    "get",
    null,
    null,
    (json) => {
      if (componentIsMounted.current) {
        setErrorMsg(null);
        setAllAdmins(
          [{ id: "placeholder", email: "Select admin profile" }].concat(json)
        );
        setDisabled(false);
      }
    },
    () => {
      if (componentIsMounted.current) {
        setErrorMsg("Connection to database failed! Please try again.");
        setDisabled(false);
      }
    },
    { Authorization: `Bearer ${token}` }
  );
}

export {
  getLocalContent,
  isVisibleById,
  sleep,
  verifyUser,
  fetchWithRetry,
  getAlbumsByCategory,
  getAlbum,
  getReviews,
  getSiteText,
  showInfoDialog,
  showConfirmDialog,
  dataURLtoBlob,
  readVideoURL,
  readImageURL,
  getWidthHeight,
  changePassword,
  createAdmin,
  deleteAdmin,
  getAdmins,
  getAlbumsForReorder,
};
