import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "./App.css";
import $ from "jquery";
import ReactPlayer from "react-player/lazy";
import Gallery from "react-photo-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPowerOff,
  faQuestionCircle,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchWithRetry,
  showInfoDialog,
  getAlbumsByCategory,
  getAlbum,
  getReviews,
  showConfirmDialog,
  sleep,
  getSiteText,
  dataURLtoBlob,
  readVideoURL,
  readImageURL,
  getWidthHeight,
  changePassword,
  createAdmin,
  deleteAdmin,
  getAdmins,
  getAlbumsForReorder,
  isVisibleById,
} from "./utils.js";
import { globalContext } from "./Context";
import { Dropdown, DropdownWithSearch } from "./Dropdowns.js";
import DragNDrop from "./DragNDrop";

const Preview = (props) => {
  const [img, setImg] = useState("");

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <div className="col px-1">
      <input
        type="file"
        className="d-none"
        id={props.id}
        onChange={async (e) => {
          let file = e.target.files[0];
          try {
            props.setDisabled(true);
            let readFile = await readImageURL(file);
            //let imgParams = await getWidthHeight(url);
            if (componentIsMounted) {
              setImg(readFile.url);
            }
          } catch (e) {
            //console.log("Error " + key);
          } finally {
            props.setDisabled(false);
          }
        }}
      />
      <div
        className={`card bg-image height hover mx-auto my-2 justify-content-center ${props.classes}`}
        style={{ backgroundImage: `url(${img})` }}
        onClick={(e) => $(`#${props.id}`).trigger("click")}
      >
        <h2 className="m-2 text-center">{props.title}</h2>
      </div>
    </div>
  );
};
const Admin = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [action, setAction] = useState("new");
  const [types] = useState(["Select type", "Album", "Review"]);
  const [editTypes] = useState([
    "Select type",
    "Album",
    "Review",
    "Order of albums",
    "Order of reviews",
  ]);
  const [allCategories] = useState([
    "Select category",
    "city",
    "nature",
    "seaside",
    "destination",
    "kids",
    "personal",
    "corporate",
    "christmas",
    "charity",
    "teambuildings",
    "cultural",
  ]);
  const [wedCategories] = useState([
    "city",
    "nature",
    "seaside",
    "destination",
    "kids",
    "personal",
  ]);
  const [corpCategories] = useState([
    "corporate",
    "christmas",
    "charity",
    "teambuildings",
    "cultural",
  ]);

  const [site, setSite] = useState();
  const [site2, setSite2] = useState();
  const [type, setType] = useState("Select type");

  const [albumId, setAlbumId] = useState(Date.now());
  const [category, setCategory] = useState("Select category");
  const [albumName, setAlbumName] = useState("");
  const [albumNameBg, setAlbumNameBg] = useState("");
  const [textEn, setTextEn] = useState("");
  const [textBg, setTextBg] = useState("");
  const [photographerEn, setPhotographerEn] = useState("");
  const [photographerBg, setPhotographerBg] = useState("");
  const [locationEn, setLocationEn] = useState("");
  const [locationBg, setLocationBg] = useState("");
  const [images, setImages] = useState([]);
  const [primaryImg, setPrimaryImg] = useState(null);
  const [primaryImgSrc, setPrimaryImgSrc] = useState(null);
  const [video, setVideo] = useState();

  const [reviewId, setReviewId] = useState(Date.now());
  const [reviewImg, setReviewImg] = useState();
  const [reviewName, setReviewName] = useState("");
  const [reviewNameBg, setReviewNameBg] = useState("");
  const [reviewTextEn, setReviewTextEn] = useState("");
  const [reviewTextBg, setReviewTextBg] = useState("");
  const [reviewAlbumLink, setReviewAlbumLink] = useState("");

  //Reorder
  const [getAlbumsState, setGetAlbumsState] = useState(0);
  const [albumsForReorder, setAlbumsForReorder] = useState([]);
  const [reviewsForReorder, setReviewsForReorder] = useState([]);
  const [reordered, setReordered] = useState(false);
  const [imagesReordered, setImagesReordered] = useState(false);

  //Edit & Delete
  const [itemsForDelete, setItemsForDelete] = useState([]);
  const [itemsInCategory, setItemsInCategory] = useState([]);
  const [getAlbumForEditState, setGetAlbumForEditState] = useState(0);
  const [allAlbumsFromServer, setAllAlbumsFromServer] = useState([]);
  const [serverCategory, setServerCategory] = useState();
  const [newImgsFromEdit, setNewImgsFromEdit] = useState([]);
  const [deletedImgsFromEdit, setDeletedImgsFromEdit] = useState([]);
  const [serverImgs, setServerImgs] = useState([]);
  const [serverVideoId, setServerVideoId] = useState();

  const [reviewsFromServer, setReviewsFromServer] = useState([]);
  const [reviewImgFromServerId, setReviewImgFromServerId] = useState();

  //Settings
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [newAdminPassword, setNewAdminPassword] = useState("");
  const [createAdminState, setCreateAdminState] = useState(0);

  const [adminForDelete, setAdminForDelete] = useState({
    id: "placeholder",
    email: "Select admin profile",
  });
  const [deleteAdminState, setDeleteAdminState] = useState(0);
  const [allAdmins, setAllAdmins] = useState([]);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [changePasswordState, setChangePasswordState] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);

  //Edit Site text
  const [sitesText] = useState([
    "Select site",
    "wedding",
    "corporate",
    "beauty",
  ]);
  const [siteText, setSiteText] = useState();
  const [siteTextEn, setSiteTextEn] = useState("");
  const [siteTextBg, setSiteTextBg] = useState("");
  const [siteTextEn2nd, setSiteTextEn2nd] = useState("");
  const [siteTextBg2nd, setSiteTextBg2nd] = useState("");
  const [siteTextAdditionalEn, setSiteTextAdditionalEn] = useState("");
  const [siteTextAdditionalBg, setSiteTextAdditionalBg] = useState("");
  const [getSiteTextForEditState, setGetSiteTextForEditState] = useState(0);

  //Preview
  const [previewTypes] = useState(["Select type", "Main page", "Galleries"]);
  const [previewType, setPreviewType] = useState(previewTypes[0]);

  const [disabled, setDisabled] = useState(false);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const onBodyClick = (e) => {
      const ids = ["albumSearchBox", "reviewSearchBox"];
      if (isVisibleById("optionsDiv")) {
        if (
          ids.indexOf(e.target.id) !== -1 ||
          ids.indexOf(e.target.parentElement.id) !== -1 ||
          ids.indexOf(e.target.parentElement.parentElement.id) !== -1
        ) {
          return;
        }
        let optionsDiv = document.getElementById("optionsDiv");
        optionsDiv.style.display = "none";
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  useEffect(() => {
    if (albumId == 1599411675968) {
      setSite("w");
      setSite2("c");
    }
    if (albumId == 1599411703312) {
      setSite("b");
    }
    if (type === types[2] || type === types[4]) {
      setSite("w");
    }
    if (wedCategories.includes(category)) {
      setSite("w");
    }
    if (corpCategories.includes(category)) {
      setSite("c");
    }
  }, [type, category, albumId]);

  useEffect(() => {
    let album = albumsForReorder.find((el) => el.id === albumId);
    if (album && album.name !== albumName) {
      album.name = albumName;
      setAlbumsForReorder([...albumsForReorder]);
    }
  }, [albumName]);

  useEffect(() => {
    if (images[0] && !primaryImg) {
      setPrimaryImg(images[0].id);
    }
    let primaryImgObj = images.find((el) => el.id === primaryImg);
    let album = albumsForReorder.find((el) => el.id === albumId);
    if (primaryImgObj && album && album.primaryImage !== primaryImgObj.src) {
      album.primaryImage = primaryImgObj.src;
      setAlbumsForReorder([...albumsForReorder]);
      setPrimaryImgSrc(primaryImgObj.src);
    }
  }, [images, primaryImg]);

  useEffect(() => {
    let review = reviewsForReorder.find((el) => el.id === reviewId);
    if (reviewImg && review) {
      review.image = reviewImg;
      setReviewsForReorder([...reviewsForReorder]);
    }
  }, [reviewImg]);

  useEffect(() => {
    let review = reviewsForReorder.find((el) => el.id === reviewId);
    if (review && review.name !== reviewName) {
      review.name = reviewName;
      setReviewsForReorder([...reviewsForReorder]);
    }
  }, [reviewName]);

  const resetStates = useCallback(() => {
    if (componentIsMounted) {
      setAlbumId(Date.now());
      setCategory(allCategories[0]);
      setAlbumName("");
      setAlbumNameBg("");
      setTextEn("");
      setTextBg("");
      setPhotographerEn("");
      setPhotographerBg("");
      setLocationEn("");
      setLocationBg("");
      setPrimaryImg(null);
      setImages([]);
      setVideo();
      setReviewId(Date.now());
      setReviewImg();
      setReviewName("");
      setReviewNameBg("");
      setReviewTextEn("");
      setReviewTextBg("");
      setReviewAlbumLink("");
      setAlbumsForReorder([]);
      setReviewsForReorder([]);
      setReordered(false);
      setImagesReordered(false);
      setItemsForDelete([]);
      setItemsInCategory([]);
      setAllAlbumsFromServer([]);
      setServerCategory();
      setDeletedImgsFromEdit([]);
      setNewImgsFromEdit([]);
      setServerImgs([]);
      setServerVideoId();
      setReviewImgFromServerId();
      setSiteTextEn("");
      setSiteTextBg("");
      setSiteTextEn2nd("");
      setSiteTextBg2nd("");
      setSiteTextAdditionalEn("");
      setSiteTextAdditionalBg("");
    }
  }, []);
  const markForDeletion = useCallback(
    (id) => {
      if (componentIsMounted) {
        if (!itemsForDelete.includes(id)) {
          setItemsForDelete([...itemsForDelete, id]);
        } else {
          setItemsForDelete(itemsForDelete.filter((el) => el !== id));
        }
      }
    },
    [itemsForDelete]
  );
  const setReviewFromServerForEdit = useCallback(
    (id) => {
      let review = reviewsFromServer.find((el) => el.id === id);
      if (componentIsMounted) {
        setReviewId(review.id);
        setReviewName(review.name);
        setReviewNameBg(review.nameBg);
        setReviewTextEn(review.textEn);
        setReviewTextBg(review.textBg);
        setReviewAlbumLink(review.link);
        setReviewImg(review.image);
        setReviewImgFromServerId(review.image.id);
      }
    },
    [reviewsFromServer]
  );
  const setAlbumFromServerForEdit = useCallback((album) => {
    try {
      setDisabled(true);

      getAlbum(album, setGetAlbumForEditState).then((albumFromServer) => {
        if (albumFromServer && componentIsMounted) {
          setAlbumId(albumFromServer.id);
          setCategory(albumFromServer.category);
          setServerCategory(albumFromServer.category);
          setAlbumName(albumFromServer.name);
          setAlbumNameBg(albumFromServer.nameBg);
          if (albumFromServer.text) {
            setTextEn(albumFromServer.text);
          } else {
            setTextEn("");
          }
          if (albumFromServer.textBg) {
            setTextBg(albumFromServer.textBg);
          } else {
            setTextBg("");
          }
          if (albumFromServer.photographer) {
            setPhotographerEn(albumFromServer.photographer);
          } else {
            setPhotographerEn("");
          }
          if (albumFromServer.photographerBg) {
            setPhotographerBg(albumFromServer.photographerBg);
          } else {
            setPhotographerBg("");
          }
          if (albumFromServer.location) {
            setLocationEn(albumFromServer.location);
          } else {
            setLocationEn("");
          }
          if (albumFromServer.locationBg) {
            setLocationBg(albumFromServer.locationBg);
          } else {
            setLocationBg("");
          }

          setPrimaryImg(
            albumFromServer.primaryImage.slice(
              albumFromServer.primaryImage.lastIndexOf("/") + 1,
              albumFromServer.primaryImage.lastIndexOf(".")
            )
          );
          let url;
          if (albumFromServer.id == 1599411675968) {
            url = "https://svatbenocafe.com";
          }
          if (albumFromServer.id == 1599411703312) {
            url = "https://new.beautycafeboutique.bg";
          }

          if (wedCategories.includes(albumFromServer.category)) {
            url = "https://svatbenocafe.com";
          }
          if (corpCategories.includes(albumFromServer.category)) {
            url = "https://eventcafeboutique.com";
          }

          if (albumFromServer.video) {
            albumFromServer.video.src = `${url}${albumFromServer.video.src}`;
            setVideo(albumFromServer.video);
            setServerVideoId(albumFromServer.video.id);
          } else {
            setVideo();
            setServerVideoId();
          }
          setImages(
            albumFromServer.images
              .sort((a, b) => a.position - b.position)
              .map((img) => {
                img.src = `${url}${img.src}`;
                return img;
              })
          );
          setServerImgs(albumFromServer.images);
          getAlbumsForReorder(
            albumFromServer.category,
            setAlbumsForReorder,
            setErrorMsg,
            setGetAlbumsState,
            componentIsMounted,
            url
          );
          setImagesReordered(false);
          setNewImgsFromEdit([]);
        }
      });
    } finally {
      if (componentIsMounted) {
        setDisabled(false);
      }
    }
  }, []);

  const setSiteTextFromServerForEdit = useCallback((site) => {
    try {
      setDisabled(true);
      getSiteText(site, setGetSiteTextForEditState).then(
        (siteTextFromServer) => {
          if (siteTextFromServer && componentIsMounted) {
            setSiteTextEn(
              siteTextFromServer[site].col1 ? siteTextFromServer[site].col1 : ""
            );
            setSiteTextBg(
              siteTextFromServer[site].col1Bg
                ? siteTextFromServer[site].col1Bg
                : ""
            );
            setSiteTextEn2nd(
              siteTextFromServer[site].col2 ? siteTextFromServer[site].col2 : ""
            );
            setSiteTextBg2nd(
              siteTextFromServer[site].col2Bg
                ? siteTextFromServer[site].col2Bg
                : ""
            );
            setSiteTextAdditionalEn(
              siteTextFromServer[site].additional
                ? siteTextFromServer[site].additional
                : ""
            );
            setSiteTextAdditionalBg(
              siteTextFromServer[site].additionalBg
                ? siteTextFromServer[site].additionalBg
                : ""
            );
          }
        }
      );
    } finally {
      if (componentIsMounted) {
        setDisabled(false);
      }
    }
  }, []);

  return (
    <div
      className="text-secondary py-5"
      onClick={(e) => {
        if (
          e.target.id === "albumSearchBox" ||
          e.target.id === "reviewSearchBox"
        ) {
          $(".options-div").show();
        } else {
          $(".options-div").hide();
        }
      }}
    >
      <div
        className={`container min-h-90 w-xl-70 font-admin ${
          disabled ? "awaiting" : ""
        }`}
      >
        <ul className="nav nav-pills rounded" role="tablist">
          <li className="nav-item mr-3 pb-1">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 active nav-link px-4"
              data-toggle="pill"
              role="tab"
              aria-controls="upload"
              aria-selected="true"
              onClick={(e) => {
                setType(types[0]);
                resetStates();
                setAction("new");
              }}
            >
              New
            </button>
          </li>
          <li className="nav-item mr-3 pb-1">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link px-4"
              data-toggle="pill"
              role="tab"
              aria-controls="info"
              aria-selected="false"
              onClick={async (e) => {
                setType(types[0]);
                resetStates();
                setAction("edit");
              }}
            >
              Edit
            </button>
          </li>
          <li className="nav-item mr-3 pb-1 ">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link "
              data-toggle="pill"
              role="tab"
              aria-controls="info"
              aria-selected="false"
              onClick={(e) => {
                setType(types[0]);
                resetStates();
                setAction("delete");
              }}
            >
              Delete
            </button>
          </li>
          <li className="nav-item mr-3 pb-1 ">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link "
              data-toggle="pill"
              role="tab"
              aria-controls="info"
              aria-selected="false"
              onClick={(e) => {
                setType(types[0]);
                resetStates();
                setAction("editSiteText");
              }}
            >
              Site Text
            </button>
          </li>
          <li className="nav-item mr-3 pb-1 ">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link "
              data-toggle="pill"
              role="tab"
              aria-controls="info"
              aria-selected="false"
              onClick={(e) => {
                setType(types[0]);
                resetStates();
                setAction("preview");
              }}
            >
              Preview
            </button>
          </li>
          <li className="nav-item pb-1 mr-1 ml-auto">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link "
              data-toggle="pill"
              role="tab"
              aria-controls="info"
              aria-selected="false"
              onClick={async (e) => {
                setType(types[0]);
                resetStates();
                setAction("settings");
              }}
            >
              Settings
            </button>
          </li>
          <li className="nav-item pb-1">
            <button
              type="button"
              className="btn btn-outline-secondary border-0 nav-link "
              onClick={() => {
                showConfirmDialog(
                  dispatch,
                  <span className="text-red">
                    <FontAwesomeIcon icon={faQuestionCircle} /> Sign out
                  </span>,
                  <div>Are you sure you want to sign out?</div>,
                  () => {
                    state.logout();
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </li>
        </ul>

        {(action === "new" || action === "edit") && (
          <>
            <form
              id="uploadForm"
              className="py-4"
              onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="form-group row">
                <label htmlFor="type" className="col-md-2 col-form-label mt-4">
                  Type
                </label>
                <Dropdown
                  classes="text-left col-md-3 col-lg-2 mt-md-4 mx-2 mx-md-0"
                  action={action}
                  selected={type}
                  setSelected={async (item) => {
                    resetStates();
                    setType(item);

                    if (action === "edit" && item === types[1]) {
                      try {
                        setDisabled(true);
                        let albums = await getAlbumsByCategory("all");
                        albums.sort((a, b) => a.name.localeCompare(b.name));
                        setAllAlbumsFromServer(albums);
                      } finally {
                        setDisabled(false);
                      }
                    }
                    if (item === types[2] || item === editTypes[4]) {
                      try {
                        setDisabled(true);
                        let reviews = await getReviews();
                        reviews = reviews.map((el) => {
                          let newEl = { ...el };
                          newEl.image.src = `https://svatbenocafe.com${el.image.src}`;
                          if (!el.link) {
                            newEl.link = "";
                          }
                          return newEl;
                        });
                        setReviewsFromServer(reviews);
                        setReviewsForReorder(reviews);
                      } catch (e) {
                        showInfoDialog(dispatch, "Error", "Please try again!");
                      } finally {
                        setDisabled(false);
                      }
                    }
                  }}
                  items={action === "edit" ? editTypes : types}
                />
              </div>
              {type === types[1] && (
                <>
                  {allAlbumsFromServer.length > 0 && (
                    <div className="form-group row">
                      <label
                        htmlFor="album"
                        className="col-md-2 col-form-label"
                      >
                        Album
                      </label>
                      <DropdownWithSearch
                        divClasses="text-left col-md-7 mx-2 mx-md-0 px-md-0"
                        id="albumSearchBox"
                        placeholder="Select Album"
                        chooseItem={async (id) => setAlbumFromServerForEdit(id)}
                        items={allAlbumsFromServer}
                      />
                    </div>
                  )}

                  {(action === "new" || (action === "edit" && albumName)) && (
                    <>
                      <div className="form-group row">
                        <label
                          htmlFor="category"
                          className="col-md-2 col-form-label"
                        >
                          Category
                        </label>
                        <Dropdown
                          classes="text-left col-md-3 col-lg-2 mx-2 mx-md-0"
                          selected={category}
                          setSelected={async (item) => {
                            let url;
                            if (wedCategories.includes(item)) {
                              url = "https://svatbenocafe.com";
                            }
                            if (corpCategories.includes(item)) {
                              url = "https://eventcafeboutique.com";
                            }
                            setReordered(false);
                            setCategory(item);
                            getAlbumsForReorder(
                              item,
                              setAlbumsForReorder,
                              setErrorMsg,
                              setGetAlbumsState,
                              componentIsMounted,
                              url
                            );
                          }}
                          items={allCategories}
                        />
                      </div>
                      {category !== allCategories[0] && (
                        <>
                          <div className="form-group row">
                            <label
                              htmlFor="order"
                              className="col-4 col-sm-2 col-form-label"
                            >
                              Albums order
                            </label>
                            {getAlbumsState === 0 && (
                              <DragNDrop
                                grid
                                newElId={albumId}
                                newElName={albumName}
                                image={primaryImg}
                                elements={albumsForReorder}
                                setElements={setAlbumsForReorder}
                                setReordered={setReordered}
                              />
                            )}
                            {getAlbumsState === 1 && (
                              <>
                                <div className="text-danger mt-2">
                                  {errorMsg}
                                </div>
                              </>
                            )}
                            {getAlbumsState === 2 && (
                              <div
                                className="spinner-border text-red text-center mx-auto my-5"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryImages"
                              className="col-4 col-sm-2 col-form-label"
                            >
                              Images
                            </label>
                            <input
                              type="file"
                              multiple
                              className="d-none"
                              id="galleryImages"
                              onChange={async (e) => {
                                let newGalleryImgs = [];
                                let files = e.target.files;
                                for (let key in files) {
                                  if (!isNaN(+key)) {
                                    try {
                                      setDisabled(true);
                                      let { blob, url, w, h } =
                                        await readImageURL(files[key]);
                                      //let imgParams = await getWidthHeight(url);
                                      newGalleryImgs.push({
                                        id: Date.now(),
                                        position:
                                          images.length > 0
                                            ? images.length + +key
                                            : +key,
                                        src: url,
                                        width: w,
                                        height: h,
                                        file: blob,
                                        type: files[key].type,
                                      });
                                    } catch (e) {
                                      //console.log("Error " + key);
                                    } finally {
                                      setDisabled(false);
                                    }
                                  }
                                }
                                setImages(
                                  images
                                    .concat(newGalleryImgs)
                                    .sort((a, b) => a.position - b.position)
                                );
                                if (action === "edit") {
                                  setNewImgsFromEdit(newGalleryImgs);
                                }
                              }}
                            />

                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={(e) =>
                                $("#galleryImages").trigger("click")
                              }
                            >
                              Upload
                            </button>
                          </div>

                          {(images.length > 0 && action === "new") ||
                          (images.length > 0 &&
                            action === "edit" &&
                            getAlbumForEditState === 0) ? (
                            <div className="px-0 ">
                              <div>
                                <Gallery
                                  photos={images}
                                  renderImage={(image) => {
                                    let img = image.photo;
                                    return (
                                      <div
                                        key={img.id}
                                        className="mr-1 mb-1 position-relative"
                                        onDrop={(e) => {
                                          e.preventDefault();
                                          let comesFromPosition =
                                            +e.dataTransfer.getData("position");
                                          let dropPosition = +img.position;
                                          let newElements = [...images];
                                          if (
                                            comesFromPosition > dropPosition
                                          ) {
                                            for (const el of newElements) {
                                              el.position = Number(el.position);
                                              if (
                                                el.position ===
                                                comesFromPosition
                                              ) {
                                                el.position = dropPosition;
                                                break;
                                              }
                                              if (el.position >= dropPosition) {
                                                el.position++;
                                              }
                                            }
                                          } else if (
                                            dropPosition > comesFromPosition
                                          ) {
                                            for (const el of newElements) {
                                              el.position = Number(el.position);
                                              if (el.position > dropPosition) {
                                                break;
                                              }
                                              if (
                                                el.position >
                                                  comesFromPosition &&
                                                el.position <= dropPosition
                                              ) {
                                                el.position--;
                                              }
                                            }
                                            newElements.find(
                                              (el) =>
                                                el.position ===
                                                comesFromPosition
                                            ).position = dropPosition;
                                          }

                                          setImages(
                                            newElements.sort(
                                              (a, b) => a.position - b.position
                                            )
                                          );
                                          setImagesReordered(true);
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                      >
                                        <div
                                          //className="col my-2"
                                          draggable={true}
                                          onDragStart={(e) => {
                                            e.dataTransfer.setData(
                                              "position",
                                              img.position
                                            );
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className={`btn ${
                                              primaryImg &&
                                              primaryImg === img.id
                                                ? "btn-success opac-100"
                                                : "btn-light opac-60"
                                            } check-btn `}
                                            onClick={(e) => {
                                              setPrimaryImg(img.id);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faCheck} />
                                            &nbsp; PRIMARY
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger remove-btn"
                                            onClick={(e) => {
                                              setImages(
                                                images.filter(
                                                  (el) => el.id !== img.id
                                                )
                                              );
                                              if (newImgsFromEdit.length > 0) {
                                                setNewImgsFromEdit(
                                                  newImgsFromEdit.filter(
                                                    (el) => el.id !== img.id
                                                  )
                                                );
                                              }
                                              if (primaryImg === img.id) {
                                                setPrimaryImg();
                                              }
                                              if (
                                                action === "edit" &&
                                                serverImgs.find(
                                                  (el) => el.id === img.id
                                                )
                                              ) {
                                                setDeletedImgsFromEdit([
                                                  ...deletedImgsFromEdit,
                                                  img.id,
                                                ]);
                                              }
                                            }}
                                          >
                                            &times;
                                          </button>
                                          <img
                                            // className="w-100 rounded"
                                            {...img}
                                            alt=""
                                          />
                                        </div>{" "}
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            getAlbumsState === 2 && (
                              <div
                                className="spinner-border text-red text-center mx-auto my-5"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            )
                          )}
                          <div className="form-group row">
                            <label
                              htmlFor="galleryVideo"
                              className="col-4 col-sm-2 col-form-label"
                            >
                              Video
                            </label>
                            <input
                              name="galleryVideo"
                              type="file"
                              className="d-none"
                              id="galleryVideo"
                              onChange={async (e) => {
                                let file = e.target.files[0];
                                let newGalleryVideo = {};
                                try {
                                  setDisabled(true);
                                  let url = await readVideoURL(file);
                                  newGalleryVideo.id = Date.now();
                                  newGalleryVideo.src = url;
                                  newGalleryVideo.file = file;
                                  setVideo(newGalleryVideo);
                                } catch (e) {
                                  //console.log("Error " + file);
                                } finally {
                                  setDisabled(false);
                                }
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={(e) => {
                                $("#galleryVideo").click();
                              }}
                            >
                              Upload
                            </button>
                          </div>
                          {video && (
                            <div className="form-group row">
                              <div className="col-md-2 col-form-label"></div>
                              <div className="px-0 col-md-10 col-lg-8">
                                <div className="w-md-75 w-lg-50 mt-2 mb-4 d-flex align-items-start min-h-150">
                                  <ReactPlayer
                                    playing
                                    url={video.src}
                                    light={primaryImgSrc}
                                    playIcon={
                                      <FontAwesomeIcon
                                        icon={faPlay}
                                        className="min-h-40"
                                      />
                                    }
                                    volume={null}
                                    muted={true}
                                    controls={true}
                                    width={"100%"}
                                    height={"auto"}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-danger ml-1"
                                    onClick={(e) => setVideo()}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="form-group row">
                            <label
                              htmlFor="galleryTitleEn"
                              className="col-md-2 col-form-label"
                            >
                              Title EN
                            </label>
                            <textarea
                              className="form-control col-md-7"
                              name="galleryTitleEn"
                              rows="1"
                              value={albumName}
                              onChange={(e) => setAlbumName(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryTitleBg"
                              className="col-md-2 col-form-label"
                            >
                              Title BG
                            </label>
                            <textarea
                              className="form-control col-md-7"
                              name="galleryTitleBg"
                              rows="1"
                              value={albumNameBg}
                              onChange={(e) => setAlbumNameBg(e.target.value)}
                            ></textarea>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="galleryTextEn"
                              className="col-md-2 col-form-label"
                            >
                              Text EN
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryTextEn"
                              rows="5"
                              value={textEn}
                              onChange={(e) => setTextEn(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryTextBg"
                              className="col-md-2 col-form-label"
                            >
                              Text BG
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryTextBg"
                              rows="5"
                              value={textBg}
                              onChange={(e) => setTextBg(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryLocationEn"
                              className="col-md-2 col-form-label"
                            >
                              Subtitle EN
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryLocationEn"
                              rows="1"
                              value={locationEn}
                              onChange={(e) => setLocationEn(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryLocationBg"
                              className="col-md-2 col-form-label"
                            >
                              Subtitle BG
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryLocationBg"
                              rows="1"
                              value={locationBg}
                              onChange={(e) => setLocationBg(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryPhotographerEn"
                              className="col-md-2 col-form-label"
                            >
                              Subimages text EN (Photo, etc.)
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryPhotographerEn"
                              rows="1"
                              value={photographerEn}
                              onChange={(e) =>
                                setPhotographerEn(e.target.value)
                              }
                            ></textarea>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="galleryPhotographerBg"
                              className="col-md-2 col-form-label"
                            >
                              Subimages text BG (Photo, etc.)
                            </label>
                            <textarea
                              className="form-control col-md-10 col-lg-8"
                              name="galleryPhotographerBg"
                              rows="1"
                              value={photographerBg}
                              onChange={(e) =>
                                setPhotographerBg(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {type === types[2] && (
                <>
                  {action === "edit" && reviewsFromServer.length > 0 && (
                    <div className="form-group row">
                      <label
                        htmlFor="reviewToEdit"
                        className="col-4 col-sm-2 col-form-label"
                      >
                        Review
                      </label>
                      <DropdownWithSearch
                        divClasses="text-left col-md-7 mx-2 mx-md-0 px-md-0"
                        id="reviewSearchBox"
                        placeholder="Select Review"
                        chooseItem={async (id) =>
                          setReviewFromServerForEdit(id)
                        }
                        items={reviewsFromServer}
                      />
                    </div>
                  )}
                  {(action === "new" || (action === "edit" && reviewName)) && (
                    <>
                      <div className="form-group row">
                        <label
                          htmlFor="order"
                          className="col-4 col-sm-2 col-form-label"
                        >
                          Reviews order
                        </label>
                        <DragNDrop
                          newElId={reviewId}
                          newElName={reviewName}
                          image={reviewImg}
                          elements={reviewsForReorder}
                          setElements={setReviewsForReorder}
                          setReordered={setReordered}
                        />
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="galleryImages"
                          className="col-4 col-sm-2 col-form-label"
                        >
                          Image
                        </label>
                        <input
                          name="reviewImage"
                          type="file"
                          className="mx-3 d-none"
                          id="reviewImage"
                          onChange={async (e) => {
                            let file = e.target.files[0];
                            let newReviewImg = {};
                            try {
                              setDisabled(true);
                              let { blob, url } = await readImageURL(file);
                              newReviewImg.id = Date.now();
                              newReviewImg.src = url;
                              newReviewImg.file = blob;
                              newReviewImg.type = file.type;
                              setReviewImg(newReviewImg);
                            } catch (e) {
                              //console.log("Error " + file);
                            } finally {
                              setDisabled(false);
                            }
                          }}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={(e) => $("#reviewImage").click()}
                        >
                          Upload
                        </button>
                      </div>
                      {reviewImg && (
                        <div className="form-group row">
                          <div className="col-md-2 col-form-label"></div>
                          <div className="px-0 col-md-3 col-lg-2">
                            <img className="w-100" src={reviewImg.src} alt="" />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="titleEn"
                          className="col-md-2 col-form-label"
                        >
                          Title EN
                        </label>
                        <textarea
                          className="form-control col-md-10 col-lg-8"
                          name="titleEn"
                          rows="1"
                          value={reviewName}
                          onChange={(e) => setReviewName(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="titleBg"
                          className="col-md-2 col-form-label"
                        >
                          Title BG
                        </label>
                        <textarea
                          className="form-control col-md-10 col-lg-8"
                          name="titleBg"
                          rows="1"
                          value={reviewNameBg}
                          onChange={(e) => setReviewNameBg(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="Link"
                          className="col-md-2 col-form-label"
                        >
                          Link to Album
                        </label>
                        <textarea
                          className="form-control col-md-10 col-lg-8"
                          name="Link"
                          rows="1"
                          value={reviewAlbumLink}
                          onChange={(e) => setReviewAlbumLink(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="textEn"
                          className="col-md-2 col-form-label"
                        >
                          Text EN
                        </label>
                        <textarea
                          className="form-control col-md-10 col-lg-8"
                          name="textEn"
                          rows="5"
                          value={reviewTextEn}
                          onChange={(e) => setReviewTextEn(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="textBg"
                          className="col-md-2 col-form-label"
                        >
                          Text BG
                        </label>
                        <textarea
                          className="form-control col-md-10 col-lg-8"
                          name="textBg"
                          rows="5"
                          value={reviewTextBg}
                          onChange={(e) => setReviewTextBg(e.target.value)}
                        ></textarea>
                      </div>
                    </>
                  )}
                </>
              )}
              {type === editTypes[3] && (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="category"
                      className="col-md-2 col-form-label"
                    >
                      Category
                    </label>
                    <Dropdown
                      classes="text-left col-md-3 col-lg-2 mx-2 mx-md-0"
                      selected={category}
                      setSelected={async (item) => {
                        let url;
                        if (wedCategories.includes(item)) {
                          url = "https://svatbenocafe.com";
                        }
                        if (corpCategories.includes(item)) {
                          url = "https://eventcafeboutique.com";
                        }

                        setReordered(false);
                        setCategory(item);
                        getAlbumsForReorder(
                          item,
                          setAlbumsForReorder,
                          setErrorMsg,
                          setGetAlbumsState,
                          componentIsMounted,
                          url
                        );
                      }}
                      items={allCategories}
                    />
                  </div>
                  {category !== allCategories[0] && (
                    <div className="form-group row">
                      <label
                        htmlFor="order"
                        className="col-4 col-sm-2 col-form-label"
                      >
                        Albums order
                      </label>
                      {getAlbumsState === 0 && (
                        <DragNDrop
                          grid
                          elements={albumsForReorder}
                          setElements={setAlbumsForReorder}
                          setReordered={setReordered}
                        />
                      )}
                      {getAlbumsState === 1 && (
                        <h6 className="text-danger mt-2">{errorMsg}</h6>
                      )}
                      {getAlbumsState === 2 && (
                        <div
                          className="spinner-border text-red text-center mx-auto my-5"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {type === editTypes[4] && (
                <div className="form-group row">
                  <label
                    htmlFor="order"
                    className="col-4 col-sm-2 col-form-label"
                  >
                    Reviews order
                  </label>
                  <DragNDrop
                    elements={reviewsForReorder}
                    setElements={setReviewsForReorder}
                    setReordered={setReordered}
                  />
                </div>
              )}
            </form>
            <button
              id="submitBtn"
              className="btn btn-secondary mt-3"
              type="submit"
              disabled={type === types[0]}
              onClick={async (e) => {
                e.preventDefault();

                if (
                  type === types[1] &&
                  (images.length === 0 ||
                    !primaryImg ||
                    !albumName ||
                    !albumNameBg)
                ) {
                  showInfoDialog(
                    dispatch,
                    "Missing critical fields",
                    "Missing critical fields: images / primary image / name in EN or BG"
                  );
                  return;
                }
                if (
                  type === types[1] &&
                  ((!textEn && textBg) || (textEn && !textBg))
                ) {
                  showInfoDialog(
                    dispatch,
                    "Missing fields",
                    "Missing text in EN or BG. Text and title for album will not be shown."
                  );
                  return;
                }

                if (
                  type === types[2] &&
                  (!reviewImg ||
                    !reviewName ||
                    !reviewNameBg ||
                    !reviewTextEn ||
                    !reviewTextBg)
                ) {
                  showInfoDialog(
                    dispatch,
                    "Missing critical fields",
                    "Please fill out all fields and upload an image"
                  );
                  return;
                }
                let params = {};
                if (type === types[1]) {
                  params = {
                    id: albumId,
                    action: action,
                    site: site,
                    position:
                      albumsForReorder.length > 0
                        ? albumsForReorder.length - 1
                        : 0,
                    category: category,
                    name: albumName,
                    nameBg: albumNameBg,
                    text: textEn,
                    textBg: textBg,
                    photographer: photographerEn,
                    photographerBg: photographerBg,
                    location: locationEn,
                    locationBg: locationBg,
                    primaryImage: primaryImg,
                    images: images,
                    video: video,
                  };
                  if (site2) {
                    params.site2 = site2;
                  }
                  if (reordered) {
                    params.reordered = reordered;
                    params.albumsOrder = albumsForReorder;
                  }
                  if (action === "edit") {
                    params.id = albumId;
                    if (imagesReordered || newImgsFromEdit.length > 0) {
                      params.imagesReordered = true;
                      params.imagesOrder = images.map((img) => img.id);
                    }
                    params.images =
                      newImgsFromEdit.length > 0 ? newImgsFromEdit : [];
                    if (deletedImgsFromEdit.length > 0) {
                      params.imagesForDelete = deletedImgsFromEdit;
                    }
                    if (
                      (serverVideoId && video && serverVideoId !== video.id) ||
                      (serverVideoId && !video)
                    ) {
                      params.videoForDelete = serverVideoId;
                    } else if (
                      serverVideoId &&
                      video &&
                      serverVideoId === video.id
                    ) {
                      params.video = null;
                    }
                  }
                }

                if (type === types[2]) {
                  params = {
                    id: reviewId,
                    action: action,
                    site: site,
                    image: reviewImg,
                    name: reviewName,
                    nameBg: reviewNameBg,
                    textEn: reviewTextEn,
                    textBg: reviewTextBg,
                    link: reviewAlbumLink,
                  };
                  if (reordered) {
                    params.reordered = reordered;
                    params.reviewsOrder = reviewsForReorder;
                  }
                  if (action === "edit") {
                    if (reviewImg.id !== reviewImgFromServerId) {
                      params.imageForDelete = reviewImgFromServerId;
                    } else {
                      params.image = null;
                    }
                  }
                }
                if (type === editTypes[3] && reordered) {
                  params.reordered = reordered;
                  params.albumsOrder = albumsForReorder;
                }
                if (type === editTypes[4] && reordered) {
                  params.reordered = reordered;
                  params.reviewsOrder = reviewsForReorder;
                }

                let formData = new FormData();
                for (let key in params) {
                  if (key === "images") {
                    let ids = [];
                    params[key].forEach((img) => {
                      formData.append(
                        img.id,
                        img.file,
                        `${img.id}.${img.type.substring(
                          img.type.indexOf("/") + 1
                        )}`
                      );

                      //console.log(formData.get(img.id));
                      ids.push(img.id);
                    });
                    formData.append("imgIds[]", ids);
                  } else if (key === "video" && params[key]) {
                    formData.append("video", params[key].file);
                    formData.append("videoId", params[key].id);
                  } else if (key === "image" && params[key]) {
                    formData.append("image", params[key].file);
                    formData.append("imageId", params[key].id);
                  } else if (key === "imagesOrder") {
                    formData.append("imagesOrder[]", params[key]);
                  } else if (key === "albumsOrder") {
                    let albumsOrder = params[key].map((album) => album.id);
                    formData.append("albumsOrder[]", albumsOrder);
                  } else if (key === "reviewsOrder") {
                    let reviewsOrder = params[key].map((review) => review.id);
                    formData.append("reviewsOrder[]", reviewsOrder);
                  } else {
                    formData.append(key, params[key]);
                  }
                }
                // console.log("from formData img order:");
                // console.log(formData.get("imagesOrder[]"));
                // console.log("from formData new imgs:");
                // console.log(formData.get("imgIds[]"));
                // console.log("video: ", formData.get("videoForDelete"));
               
                let endpoint =
                  type === types[1] || type === editTypes[3]
                    ? "galleries"
                    : "reviews";
                try {
                  setDisabled(true);
                  await fetchWithRetry(
                    `https://api.svatbenocafe.com/${endpoint}.php`,
                    "post",
                    null,
                    formData,
                    (json) => {
                      // console.log(json);
                      // return;
                      showInfoDialog(
                        dispatch,
                        "Success",
                        "Submit successful",
                        false,
                        () => window.location.reload()
                      );
                    },
                    (json) => {
                      // console.log("error func", json);
                      showInfoDialog(
                        dispatch,
                        "Error",
                        <>
                          Error in submit. Please try again!
                          <br />
                          Errors:
                          <ul>
                            {json.message && json.message.map((err, index) => (
                              <li key={`message${index}`}>{err}</li>
                            ))}
                          </ul>
                        </>
                      );
                    },
                    { Authorization: `Bearer ${state.user.token}` }
                  );
                } catch (e) {
                  showInfoDialog(
                    dispatch,
                    "Error",
                    <>
                      Error in submit. Please try again!
                      <br />
                      Error: {e}
                    </>
                  );
                } finally {
                  setDisabled(false);
                }
              }}
            >
              Submit
            </button>
          </>
        )}
        {action === "delete" && (
          <>
            <form id="deleteForm" className="py-4">
              <div className="form-group row">
                <label htmlFor="type" className="col-md-2 col-form-label mt-4">
                  Type
                </label>
                <Dropdown
                  classes={"text-left col-md-3 col-lg-2 mt-md-4 mx-2 mx-md-0"}
                  selected={type}
                  setSelected={async (item) => {
                    resetStates();
                    setType(item);
                    let type = item;
                    if (type === "Review") {
                      try {
                        setDisabled(true);
                        let reviews = await getReviews();
                        setItemsInCategory(reviews);
                      } catch (e) {
                        showInfoDialog(dispatch, "Error", "Please try again!");
                      } finally {
                        setDisabled(false);
                      }
                    }
                  }}
                  items={types}
                />
              </div>
              {type === types[1] && (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="category"
                      className="col-md-2 col-form-label"
                    >
                      Category
                    </label>
                    <Dropdown
                      classes="text-left col-md-3 col-lg-2 mx-2 mx-md-0"
                      selected={category}
                      setSelected={async (item) => {
                        resetStates();
                        setCategory(item);
                        let category = item;
                        let items = [];
                        try {
                          setDisabled(true);
                          items = await getAlbumsByCategory(category);
                          items = items.filter(
                            (el) => el.id !== "1599411675968" //restrict Decoration album delete (needed for corp)
                          );
                        } catch (e) {
                          showInfoDialog(
                            dispatch,
                            "Error",
                            "Please try again!"
                          );
                        } finally {
                          setDisabled(false);
                        }
                        setItemsInCategory(items);
                      }}
                      items={allCategories}
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2 col-form-label"></div>
                    <div className="px-0 col-md-10 col-lg-8">
                      <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 mt-2 mb-4">
                        {itemsInCategory.map((item) => {
                          return (
                            <div
                              className={`custom-control custom-checkbox pr-3`}
                              key={item.id}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`c${item.id}`}
                                checked={itemsForDelete.includes(item.id)}
                                onChange={(e) => {
                                  markForDeletion(item.id);
                                }}
                              />
                              <label
                                type="text"
                                className="custom-control-label"
                                htmlFor={`c${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {type === types[2] && (
                <div className="form-group row">
                  <div className="col-md-2 col-form-label"></div>
                  <div className="px-0 col-md-10 col-lg-8">
                    <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 mt-2 mb-4">
                      {itemsInCategory.map((item) => {
                        return (
                          <div
                            className="mt-2 custom-control custom-checkbox"
                            key={item.id}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`c${item.id}`}
                              checked={itemsForDelete.includes(item.id)}
                              onChange={(e) => {
                                markForDeletion(item.id);
                              }}
                            />
                            <label
                              type="text"
                              className="custom-control-label"
                              htmlFor={`c${item.id}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </form>
            <button
              id="deleteBtn"
              className="btn btn-secondary mt-3"
              type="submit"
              disabled={type === types[0]}
              onClick={async (e) => {
                e.preventDefault();

                let params = {
                  ids: itemsForDelete,
                  site: site,
                };

                let endpoint = type === types[1] ? "galleries" : "reviews";
                try {
                  setDisabled(true);
                  await fetchWithRetry(
                    `https://api.svatbenocafe.com/${endpoint}.php`,
                    "delete",
                    params,
                    null,
                    (json) => {
                      // console.log(json);
                      // return;
                      showInfoDialog(
                        dispatch,
                        "Success",
                        "Delete successful",
                        false,
                        () => window.location.reload()
                      );
                    },
                    (json) => {
                      showInfoDialog(
                        dispatch,
                        "Error",
                        <>
                          Error in delete.
                          <br />
                          Errors:
                          <ul>
                            {json.message && json.message.map((err, index) => (
                              <li key={`message${index}`}>{err}</li>
                            ))}
                          </ul>
                        </>
                      );
                    },
                    { Authorization: `Bearer ${state.user.token}` }
                  );
                } catch (e) {
                  showInfoDialog(
                    dispatch,
                    "Error",
                    <>
                      Error in delete. Please try again!
                      <br />
                      Error: {e}
                    </>
                  );
                } finally {
                  setDisabled(false);
                }
              }}
            >
              Delete
            </button>
          </>
        )}
        {action === "settings" && (
          <>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 px-0 px-md-2">
                    <ul className="nav nav-tabs mb-4" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link text-secondary active"
                          data-toggle="tab"
                          href="#changePassword"
                          role="tab"
                          aria-controls="changePassword"
                          aria-selected="true"
                        >
                          Password
                        </a>
                      </li>
                      {state.user.superAdmin === "1" && (
                        <>
                          <li className="nav-item">
                            <a
                              className="nav-link text-secondary"
                              data-toggle="tab"
                              href="#createAdmin"
                              role="tab"
                              aria-controls="createAdmin"
                              aria-selected="false"
                            >
                              Create admin
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-secondary"
                              data-toggle="tab"
                              href="#deleteAdmin"
                              role="tab"
                              aria-controls="deleteAdmin"
                              aria-selected="false"
                              onClick={(e) => {
                                getAdmins(
                                  state.user.token,
                                  setErrorMsg,
                                  setAllAdmins,
                                  componentIsMounted,
                                  setDisabled
                                );
                              }}
                            >
                              Delete admin
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                    <div className="tab-content ml-1">
                      <div
                        className="tab-pane fade"
                        id="createAdmin"
                        role="tabpanel"
                        aria-labelledby="createAdmin-tab"
                      >
                        <div className="row mb-4">
                          {createAdminState !== 2 && (
                            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                              <h3 className="mb-3">Create admin profile</h3>
                              <form
                                className=""
                                id="createAdminForm"
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  createAdmin(
                                    state.user.token,
                                    newAdminEmail,
                                    newAdminPassword,
                                    setErrorMsg,
                                    setCreateAdminState,
                                    setNewAdminEmail,
                                    setNewAdminPassword,
                                    componentIsMounted,
                                    setDisabled
                                  );
                                }}
                              >
                                <div className="form-label-group mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="New Admin Email"
                                    required
                                    autoComplete="off"
                                    value={newAdminEmail}
                                    onChange={(e) =>
                                      setNewAdminEmail(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="form-label-group mb-3">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="New Admin Password"
                                    required
                                    autoComplete="off"
                                    value={newAdminPassword}
                                    onChange={(e) =>
                                      setNewAdminPassword(e.target.value)
                                    }
                                  />
                                </div>

                                <button
                                  id="createAdminBtn"
                                  className="btn btn-red btn-block text-uppercase"
                                  type="submit"
                                >
                                  Create
                                </button>
                                {errorMsg && (
                                  <h5 className="text-danger mt-4">
                                    {errorMsg}
                                  </h5>
                                )}
                                {createAdminState === 1 && (
                                  <h5 className="text-red mt-4">
                                    New admin was successfully created!
                                  </h5>
                                )}
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="deleteAdmin"
                        role="tabpanel"
                        aria-labelledby="deleteAdmin-tab"
                      >
                        <div className="row mb-4">
                          {deleteAdminState !== 2 && (
                            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                              <h3 className="mb-3">Delete admin profile</h3>
                              <form
                                className=""
                                id="deleteAdminForm"
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  deleteAdmin(
                                    state.user.token,
                                    adminForDelete,
                                    setAdminForDelete,
                                    allAdmins,
                                    setAllAdmins,
                                    setErrorMsg,
                                    setDeleteAdminState,
                                    componentIsMounted,
                                    setDisabled
                                  );
                                }}
                              >
                                <div className="form-label-group mb-3">
                                  <Dropdown
                                    classes={"text-left"}
                                    selected={adminForDelete.email}
                                    setSelected={async (item) => {
                                      setAdminForDelete(item);
                                    }}
                                    items={allAdmins}
                                  />
                                </div>

                                <button
                                  id="deleteAdminBtn"
                                  className="btn btn-red btn-block text-uppercase"
                                  type="submit"
                                >
                                  Delete
                                </button>
                                {errorMsg && (
                                  <h5 className="text-danger mt-4">
                                    {errorMsg}
                                  </h5>
                                )}
                                {deleteAdminState === 1 && (
                                  <h5 className="text-red mt-4">
                                    Admin profile was successfully deleted!
                                  </h5>
                                )}
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade  show active"
                        id="changePassword"
                        role="tabpanel"
                        aria-labelledby="changePassword-tab"
                      >
                        <div className="row mb-4">
                          {changePasswordState !== 2 && (
                            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                              <h3 className="mb-3">Change password</h3>
                              <form
                                className=""
                                id="changePass"
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  changePassword(
                                    state.user.token,
                                    state.user.email,
                                    password,
                                    newPassword,
                                    newPassword2,
                                    setErrorMsg,
                                    setChangePasswordState,
                                    setPassword,
                                    setNewPassword,
                                    setNewPassword2,
                                    componentIsMounted,
                                    setDisabled
                                  );
                                }}
                              >
                                <div className="form-label-group mb-3">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Old Password"
                                    required
                                    autoComplete="off"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="form-label-group mb-3">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="New Password"
                                    required
                                    autoComplete="off"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="form-label-group mb-3">
                                  <input
                                    type="password"
                                    id="signUpPassword"
                                    className="form-control"
                                    placeholder="Retype New Password"
                                    autoComplete="off"
                                    required
                                    value={newPassword2}
                                    onChange={(e) =>
                                      setNewPassword2(e.target.value)
                                    }
                                  />
                                </div>
                                <button
                                  id="changePassBtn"
                                  className="btn btn-red btn-block text-uppercase"
                                  type="submit"
                                >
                                  Change
                                </button>
                                {errorMsg && (
                                  <h5 className="text-danger mt-4">
                                    {errorMsg}
                                  </h5>
                                )}
                                {changePasswordState === 1 && (
                                  <h5 className="text-red mt-4">
                                    Your password was successfully updated!
                                  </h5>
                                )}
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {action === "editSiteText" && (
          <>
            <form
              id="siteTextForm"
              className="py-4"
              onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="form-group row">
                <label htmlFor="site" className="col-md-2 col-form-label mt-4">
                  Site
                </label>
                <Dropdown
                  classes="text-left col-md-3 col-lg-2 mt-md-4 mx-2 mx-md-0"
                  action={action}
                  selected={siteText}
                  setSelected={async (item) => {
                    resetStates();
                    setSiteText(item);
                    setSiteTextFromServerForEdit(item);
                  }}
                  items={sitesText}
                />
              </div>
              {getSiteTextForEditState === 0 && siteText !== sitesText[0] && (
                <>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextEn"
                      className="col-md-2 col-form-label"
                    >
                      Text List EN
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextEn"
                      rows="5"
                      value={siteTextEn}
                      onChange={(e) => setSiteTextEn(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextBg"
                      className="col-md-2 col-form-label"
                    >
                      Text List BG
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextBg"
                      rows="5"
                      value={siteTextBg}
                      onChange={(e) => setSiteTextBg(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextEn2nd"
                      className="col-md-2 col-form-label"
                    >
                      Text List 2nd column EN
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextEn2nd"
                      rows="5"
                      value={siteTextEn2nd}
                      onChange={(e) => setSiteTextEn2nd(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextBg2nd"
                      className="col-md-2 col-form-label"
                    >
                      Text List 2nd column BG
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextBg2nd"
                      rows="5"
                      value={siteTextBg2nd}
                      onChange={(e) => setSiteTextBg2nd(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextAdditionalEn"
                      className="col-md-2 col-form-label"
                    >
                      Text Additional EN
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextAdditionalEn"
                      rows="5"
                      value={siteTextAdditionalEn}
                      onChange={(e) => setSiteTextAdditionalEn(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="siteTextAdditionalBg"
                      className="col-md-2 col-form-label"
                    >
                      Text Additional BG
                    </label>
                    <textarea
                      className="form-control col-md-10 col-lg-8"
                      name="siteTextAdditionalBg"
                      rows="5"
                      value={siteTextAdditionalBg}
                      onChange={(e) => setSiteTextAdditionalBg(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    id="submitSiteTextBtn"
                    className="btn btn-secondary mt-3"
                    type="submit"
                    onClick={async (e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      let params = {
                        id: site,
                        site: site,
                        col1: siteTextEn,
                        col1Bg: siteTextBg,
                        col2: siteTextEn2nd,
                        col2Bg: siteTextBg2nd,
                        additional: siteTextAdditionalEn,
                        additionalBg: siteTextAdditionalBg,
                      };

                      try {
                        setDisabled(true);
                        await fetchWithRetry(
                          `https://api.svatbenocafe.com/text.php`,
                          "post",
                          params,
                          null,
                          (json) => {
                            // console.log(json);
                            // return;
                            showInfoDialog(
                              dispatch,
                              "Success",
                              "Submit successful",
                              false,
                              () => window.location.reload()
                            );
                          },
                          (json) => {
                            //console.log(json);
                            showInfoDialog(
                              dispatch,
                              "Error",
                              <>
                                Error in submit. Please try again!
                                <br />
                                Errors:
                                <ul>
                                  {json.message && json.message.map((err, index) => (
                                    <li key={`message${index}`}>{err}</li>
                                  ))}
                                </ul>
                              </>
                            );
                          },
                          { Authorization: `Bearer ${state.user.token}` }
                        );
                      } catch (e) {
                        showInfoDialog(
                          dispatch,
                          "Error",
                          <>
                            Error in submit. Please try again!
                            <br />
                            Error: {e}
                          </>
                        );
                      } finally {
                        setDisabled(false);
                      }
                    }}
                  >
                    Submit
                  </button>
                </>
              )}
              {getSiteTextForEditState === 1 && (
                <div className="text-center">
                  <div className="text-danger mt-2">
                    Connection to server failed!
                  </div>
                  <a
                    href="/#"
                    className="text-red mt-2"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      setSiteTextFromServerForEdit(site);
                    }}
                  >
                    Click to reload
                  </a>
                </div>
              )}
              {getSiteTextForEditState === 2 && (
                <div
                  className="spinner-border text-red fixed-center"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </form>
          </>
        )}
        {action === "preview" && (
          <>
            <div className="form-group row">
              <label htmlFor="site" className="col-md-2 col-form-label mt-4">
                Preview type
              </label>
              <Dropdown
                classes="text-left col-md-3 col-lg-2 mt-md-4 mx-2 mx-md-0"
                action={action}
                selected={previewType}
                setSelected={async (item) => {
                  setPreviewType(item);
                }}
                items={previewTypes}
              />
            </div>
            {previewType === previewTypes[1] && (
              <div className="row row-cols-1 row-cols-md-3">
                <Preview
                  setDisabled={setDisabled}
                  addTitle
                  title="Corporate &amp; cultural events"
                  id="left"
                />
                <Preview
                  setDisabled={setDisabled}
                  addTitle
                  title="Weddings &amp; Personal events"
                  id="center"
                />
                <Preview
                  setDisabled={setDisabled}
                  addTitle
                  title="Style &amp; Beauty"
                  id="right"
                />
              </div>
            )}
            {previewType === previewTypes[2] && (
              <div className="row row-cols-1 row-cols-md-3">
                <Preview
                  setDisabled={setDisabled}
                  classes="bottom-pos"
                  title="City Weddings"
                  id="left"
                />
                <Preview
                  setDisabled={setDisabled}
                  title="Nature Weddings"
                  id="center"
                />
                <Preview
                  setDisabled={setDisabled}
                  title="Seaside Weddings"
                  id="right"
                />
                <Preview
                  setDisabled={setDisabled}
                  title="Destination Weddings"
                  id="left2"
                />
                <Preview
                  setDisabled={setDisabled}
                  title="Kids Parties"
                  id="center2"
                />
                <Preview
                  setDisabled={setDisabled}
                  title="Personal Events &amp; Decoration"
                  id="right2"
                />
              </div>
            )}
          </>
        )}
      </div>
      {disabled && (
        <div className="fixed-center">
          <div
            className="spinner-border text-red text-center mx-auto my-5"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Admin;
