import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";

const Dropdown = (props) => {
  return (
    <>
      <button
        className={
          (props.selected === props.items[0]
            ? "dropdown-item-faded-color "
            : "") +
          "dropdown-btn custom-select selectpicker " +
          props.classes
        }
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.selected}
      </button>

      <div className="dropdown-menu p-0">
        {props.items.map((item, index) => {
          return (
            <button
              type="button"
              className={`dropdown-item px-2 ${props.itemsClasses}`}
              value={item.name ? item.name : item.email ? item.email : item}
              key={index.toString()}
              onClick={(e) => {
                props.setSelected(item);
              }}
            >
              {item.name ? item.name : item.email ? item.email : item}
            </button>
          );
        })}
      </div>
    </>
  );
};

const DropdownWithSearch = (props) => {
  const [text, setText] = useState("");
  const [preSelected, setPreSelected] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.items);
  const optionsDiv = useRef(null);

  useEffect(() => {
    let newFfilteredItems =
      text.length === 0
        ? props.items
        : props.items.filter((item) => {
            return item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
          });
    setFilteredItems(newFfilteredItems);
  }, [text]);

  return (
    <div className={"drop-down-search " + props.divClasses}>
      <input
        type="text"
        autoComplete="off"
        id={props.id}
        className={"form-control custom-select selectpicker " + props.classes}
        placeholder={props.placeholder}
        value={text}
        onFocus={(e) => {
          if (text.length === 0) {
            setShowOptions(true);
          }
        }}
        onChange={(e) => {
          setShowOptions(true);
          setPreSelected(0);
          setText(e.target.value);
        }}
        onKeyDown={(e) => {
          let keycode = e.keyCode ? e.keyCode : e.which;
          if (keycode === 13 && filteredItems.length > preSelected) {
            setShowOptions(false);
            setText(filteredItems[preSelected].name);
            props.chooseItem(filteredItems[preSelected].id);
          } else if (keycode === 40 && filteredItems.length - 1 > preSelected) {
            $(optionsDiv.current).scrollTop(0);
            $(optionsDiv.current).scrollTop((preSelected + 1) * 30);

            setPreSelected(preSelected + 1);
          } else if (keycode === 38 && preSelected > 0) {
            $(optionsDiv.current).scrollTop(0);
            $(optionsDiv.current).scrollTop((preSelected - 1) * 30);

            setPreSelected(preSelected - 1);
          }
        }}
      ></input>

      {filteredItems.length > 0 && showOptions && (
        <div className="options-div list-group scroll " id="optionsDiv" ref={optionsDiv}>
          {filteredItems.map((item, index) => {
            return (
              <a
                key={item.name}
                className={
                  "list-group-item list-group-item-action " +
                  (index === preSelected ? "active" : "")
                }
                href="#/"
                value={item.name}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOptions(false);
                  setText(item.name);
                  props.chooseItem(item.id);
                }}
              >
                {item.name !== "" ? item.name : "New"}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
export { Dropdown, DropdownWithSearch };
