import React from "react";

const DragNDrop = (props) => {
  let elements = props.elements.sort((a, b) => a.position - b.position);
  elements.forEach((el, index) => (el.position = index));
  if (props.newElId && !elements.find((el) => el.id === props.newElId)) {
    elements.push({
      id: props.newElId,
      position: elements.length,
      name: props.newElName,
    });
  }

  return (
    <div className="col-md-10 col-lg-8 px-0">
      <div
        className={
          props.grid
            ? "row row-cols-2 row-cols-sm-3 row-cols-lg-4 mt-2 mb-4"
            : "mt-2 mb-4"
        }
      >
        {props.elements.map((el, index) => {
          return (
            <div
              className="col mb-2"
              key={index}
              onDrop={(e) => {
                e.preventDefault();
              
                let comesFromPosition = +e.dataTransfer.getData("position");
                let dropPosition = index;
                
                let newElements = [...props.elements];
                if (comesFromPosition > dropPosition) {
                  for (const el of newElements) {
                    el.position = Number(el.position);
                    if (el.position === comesFromPosition) {
                      el.position = dropPosition;
                      break;
                    }
                    if (el.position >= dropPosition) {
                      el.position++;
                    }
                  }
                } else if ( comesFromPosition < dropPosition) {
                  for (const el of newElements) {
                    el.position = Number(el.position);
                    if (el.position > dropPosition) {
                      break;
                    }
                    if (
                      el.position > comesFromPosition &&
                      el.position <= dropPosition
                    ) {
                      el.position--;
                    }
                  }
                  newElements.find(
                    (el) => el.position === comesFromPosition
                  ).position = dropPosition;
                }
               

                props.setElements(
                  newElements.sort((a, b) => a.position - b.position)
                );
                props.setReordered(true);
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <div
                className={
                  props.grid
                    ? "text-center bg-image hover h-30 rounded"
                    : "text-left bg-dark-pink border rounded w-100 p-2"
                }
                style={
                  props.grid
                    ? {
                        backgroundImage: el.primaryImage
                          ? `url(${el.primaryImage})`
                          : el.image && el.image.src
                          ? `url(${el.image.src})`
                          : "linear-gradient(black, pink, black)",
                      }
                    : {}
                }
                draggable={true}
                onDragStart={(e) => {
                  e.dataTransfer.setData("position", el.position);
                }}
              >
                {props.grid ? (
                  <h6>{el.name ? el.name : "New"}</h6>
                ) : (
                  <>
                    {el.image && (
                      <img src={el.image.src} className="d-inline-block w-25" />
                    )}
                    <h6 className="d-inline-block ml-3">
                      {el.name ? el.name : "New"}
                    </h6>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DragNDrop;
